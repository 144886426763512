import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const TopSlider = () => {
  return (
    <div style={{ marginTop: "80px" }}>
      <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}>
        <div>
          <img src="../img/slider/5.jpg" />
        </div>
        <div>
          <img src="../img/slider/1.jpg" />
        </div>
        <div>
          <img src="../img/slider/2.jpg" />
        </div>
        <div>
          <img src="../img/slider/3.jpg" />
        </div>
        <div>
          <img src="../img/slider/4.jpg" />
        </div>
      </Carousel>
    </div>
  );
};

export default TopSlider;
