import React, { Component } from "react";
import Navigation from "./components/navigation";
import Home from "./Home";
import Packages from "./Packages";
import TopSlider from "./components/TopSlider";
import Features from "./components/features";
import About from "./components/about";
import Services from "./components/services";
import Gallery from "./components/gallery";
import Testimonials from "./components/testimonials";
import Team from "./components/Team";
import Contact from "./components/contact";
import JsonData from "./data/data.json";
import { Routes, Route, Link } from "react-router-dom";

export class App extends Component {
  state = {
    landingPageData: {}
  };
  getlandingPageData() {
    this.setState({ landingPageData: JsonData });
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="packages" element={<Packages />} />
        </Routes>
      </div>
    );
  }
}

export default App;
