import React, { Component } from "react";

export class Services extends Component {
  render() {
    return (
      <div id="services" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2 title="Services of Sathsara Lanka Sounds and Projectors">
              Our Services
            </h2>
            <p>
              Supply of projectors, screens and sound equipment for workshops,
              seminars, meetings, weddings and all other occasions
            </p>
            <p>
              වැඩමුළු, සම්මන්ත්‍රණ, රැස්වීම්, මංගල උත්සව සහ අනෙකුත් සියලුම
              අවස්ථාවන් සඳහා ප්‍රොජෙක්ටර් සහ ශබ්ද උපකරණ සැපයීම
            </p>
          </div>
          <div className="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div key={`${d.name}-${i}`} className="col-md-4">
                    {" "}
                    <div className="sl-icon"></div>
                    <img className="sl-icon" src={d.icon}></img>
                    <div className="service-desc">
                      <h3>{d.name}</h3>
                      <p>{d.text}</p>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
