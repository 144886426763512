import React, { Component } from "react";

export class about extends Component {
  render() {
    return (
      <div id="about">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {" "}
              <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="about-text">
                <h2 title="About sathsara lanka sounds and projectors">
                  About Us
                </h2>
                <p>
                  Sathsara Lanka (Founded in 1990) is a trusted institution that
                  caters all the multimedia needs of the general public at the
                  grassroots level. With the advancement of new technology, high
                  quality sound equipment was provided to suit the needs of the
                  customers. We are affiliated with renowned trading companies
                  in Sri Lanka and we are committed to provide quality service
                  with the utmost confidence and responsibility among all our
                  customers.
                </p>
                <p>
                  1990 වර්ෂයේ ආරම්භ කරන ලද සත්සර ලංකා ආයතනය බිම්මට්ටමේ සිටින
                  සාමාන්‍ය ජනතාවගේ ශබ්ධ විකාශන අවශ්‍යතාවය සපුරාලමින් විශ්වාසය
                  දිනාගත් ආයතනයකි. නව තාක්ෂනය දියුණු වීමත් සමග ඉදිරියට යමින්
                  පාරිභෝගික අවශ්‍යතාවයට ගැලපෙන පරිදි උසස් තත්වයේ ශබ්ද උපකරණ සපයන
                  ලදී. එතනින් නොනැවතුන අප ආයතනය 1999 වර්ෂයේදී බහුකාර්ය
                  ප්‍රොජෙක්ටර් කුලියට දීම ආරම්භ කරන ලදී. ශ්‍රී ලංකාවේ ප්‍රසිද්ද
                  වෙළද ව්‍යාපාරික ආයතන අප හා සම්බන්ධවී සිටින අතර අපගේ සියලු
                  පාරිභෝගිකයන් අතර දැඩි විශ්වාසයෙන් සහ වගකීමෙන් කටුතුකර ගුණාත්මක
                  සේවාවක් ලබාදීමට අප කැපවි සිටින්නෙමු.
                </p>
                {/* <h3>Why Choose Us?</h3> */}
                {/* <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data
                        ? this.props.data.Why.map((d, i) => (
                            <li key={`${d}-${i}`}>{d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data
                        ? this.props.data.Why2.map((d, i) => (
                            <li key={`${d}-${i}`}> {d}</li>
                          ))
                        : "loading"}
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default about;
