import React, { Component } from "react";
import Navigation from "./components/navigation";
import Header from "./components/header";
import TopSlider from "./components/TopSlider";
import Features from "./components/features";
import About from "./components/about";
import Services from "./components/services";
import Gallery from "./components/gallery";
import Testimonials from "./components/testimonials";
import Team from "./components/Team";
import Contact from "./components/contact";
import JsonData from "./data/data.json";
import Packages from "./Packages";

export class Home extends Component {
  state = {
    landingPageData: {}
  };
  getlandingPageData() {
    this.setState({ landingPageData: JsonData });
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
      <div>
        {/* <Header data={this.state.landingPageData.Header} /> */}
        <TopSlider />
        <Services data={this.state.landingPageData.Services} />
        {/* <Features data={this.state.landingPageData.Features} /> */}
        <Gallery />
        <About data={this.state.landingPageData.About} />
        {/* <Testimonials data={this.state.landingPageData.Testimonials} />
        <Team data={this.state.landingPageData.Team} /> */}
        <Packages />
        <Contact data={this.state.landingPageData.Contact} />
      </div>
    );
  }
}

export default Home;
